<template>
	<modal class="NoxModalServerSettings" name="NoxModalServerSettings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addServer'">Добавление сервера</span>
					<span v-else-if="noxType == 'editServer'">Редактирование сервера</span>
					<span v-else-if="noxType == 'deleteServer'">Удаление сервера</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<div v-if="['addServer', 'editServer'].includes(noxType)">
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Номер сервера:</div>
								<div class="nox_modal_info_row_value">
									<input type="number" class="nox_input" v-model.number="noxServerId" placeholder="номер сервера" autocomplete="off">
									<div v-html="noxAlertServerId"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" v-if="noxServerType == 0">
								<div class="nox_modal_info_row_label">Адрес мастера:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxServerMaster" placeholder="адрес мастера (пример: https://192.168.0.1:80)" autocomplete="off">
									<div v-html="noxAlertServerMaster"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" v-if="noxServerType == 0">
								<div class="nox_modal_info_row_label">Адрес слейва:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxServerSlave" placeholder="адрес слейва (пример: https://192.168.0.1:80)" autocomplete="off">
									<div v-html="noxAlertServerSlave"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" v-if="noxServerType == 1">
								<div class="nox_modal_info_row_label">Адрес ретранслятора:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxServerRelay" placeholder="адрес ретранслятора (пример: https://192.168.0.1:80)" autocomplete="off">
									<div v-html="noxAlertServerRelay"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Описание сервера:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxServerDescription" placeholder="описание сервера" autocomplete="off">
									<div v-html="noxAlertServerDescription"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'deleteServer'">
							<p>Вы действительно хотите удалить сервер?</p>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="submit" class="nox_button common green" @click="axios" v-if="noxType == 'addServer'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
						<button type="submit" class="nox_button common green" @click="axios" v-else-if="noxType == 'editServer'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
						<button type="submit" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteServer'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertServerId: '',
			noxAlertServerMaster: '',
			noxAlertServerSlave: '',
			noxAlertServerRelay: '',
			noxAlertServerDescription: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxServerId: '',
			noxServerMasterProtocol: '',
			noxServerMasterAddress: '',
			noxServerMasterPort: '',
			noxServerMaster: '',
			noxServerSlaveProtocol: '',
			noxServerSlaveAddress: '',
			noxServerSlavePort: '',
			noxServerSlave: '',
			noxServerRelayProtocol: '',
			noxServerRelayAddress: '',
			noxServerRelayPort: '',
			noxServerRelay: '',
			noxServerDescription: '',
			noxServerType: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxServerId = '';
				this.noxServerMasterProtocol = '';
				this.noxServerMasterAddress = '';
				this.noxServerMasterPort = '';
				this.noxServerMaster = '';
				this.noxServerSlaveProtocol = '';
				this.noxServerSlaveAddress = '';
				this.noxServerSlavePort = '';
				this.noxServerSlave = '';
				this.noxServerRelayProtocol = '';
				this.noxServerRelayAddress = '';
				this.noxServerRelayPort = '';
				this.noxServerRelay = '';
				this.noxServerDescription = '';
				this.noxServerType = event.params.serverType;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editServer') {

					this.noxData = this.$store.state.noxServerSettings[this.noxIndex];
					this.noxServerId = this.noxData.server_id;
					this.noxServerMaster = (this.noxData.master_protocol ? this.noxData.master_protocol + '://' : '') + this.noxData.master_address + ':' + this.noxData.master_port;
					this.noxServerSlave = (this.noxData.slave_protocol ? this.noxData.slave_protocol + '://' : '') + this.noxData.slave_address + ':' + this.noxData.slave_port;
					this.noxServerRelay = (this.noxData.relay_protocol ? this.noxData.relay_protocol + '://' : '') + this.noxData.relay_address + ':' + this.noxData.relay_port;
					this.noxServerDescription = this.noxData.description;
					this.noxServerType = this.noxData.type;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertServerId = '';
				this.noxAlertServerMaster = '';
				this.noxAlertServerSlave = '';
				this.noxAlertServerRelay = '';
				this.noxAlertServerDescription = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id сервера.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id сервера.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать номер сервера.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный номер сервера.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать адрес мастера.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный адрес мастера.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать адрес слейва.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный адрес слейва.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать адрес ретранслятора.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный адрес ретранслятора.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать описание сервера.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верное описание сервера.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать тип сервера.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верный тип сервера.'; }
				else if (i == 15) { this.noxTemp = 'Id сервера не найден в системе.'; }
				else if (i == 16) { this.noxTemp = 'Такой номер сервера уже есть в системе.'; }
				else if (i == 17) { this.noxTemp = 'Такой адрес мастера уже есть в системе.'; }
				else if (i == 18) { this.noxTemp = 'Такой адрес слейва уже есть в системе.'; }
				else if (i == 19) { this.noxTemp = 'Такой адрес ретранслятора уже есть в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(event) {

				event.preventDefault();

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/admin/account/bots/servers',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addServer', 'editServer'].includes(_this.noxType)) {
						if (!_this.noxServerId) {
							_this.noxAlertServerId = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxServerId) || _this.noxServerId < 1) {
							_this.noxAlertServerId = _this.getError(4);
						}
						if (_this.noxServerType == 0) {
							if (!_this.noxServerMaster) {
								_this.noxAlertServerMaster = _this.getError(5);
							}
							else {
								var noxServerMasterData = _this.noxServerMaster.split('://', 2);
								_this.noxServerMasterProtocol = noxServerMasterData.length > 1 && noxServerMasterData[0] !== undefined ? noxServerMasterData[0] : '';
								noxServerMasterData = String(noxServerMasterData.length > 1 ? noxServerMasterData[1] : noxServerMasterData).split(':', 2);
								_this.noxServerMasterAddress = noxServerMasterData[0] !== undefined ? noxServerMasterData[0] : '';
								_this.noxServerMasterPort = noxServerMasterData[1] !== undefined ? noxServerMasterData[1] : 0;
								if (_this.noxServerMasterProtocol && !['http', 'https'].includes(_this.noxServerMasterProtocol)) {
									_this.noxAlertServerMaster = _this.getError(6);
								}
								else if (!_this.$store.state.noxRegexServerName.test(_this.noxServerMasterAddress) && !_this.$store.state.noxRegexServerAddr.test(_this.noxServerMasterAddress)) {
									_this.noxAlertServerMaster = _this.getError(6);
								}
								else if (!_this.$store.state.noxRegexServerPort.test(_this.noxServerMasterPort)) {
									_this.noxAlertServerMaster = _this.getError(6);
								}
							}
							if (!_this.noxServerSlave) {
								_this.noxAlertServerSlave = _this.getError(7);
							}
							else {
								var noxServerSlaveData = _this.noxServerSlave.split('://', 2);
								_this.noxServerSlaveProtocol = noxServerSlaveData.length > 1 && noxServerSlaveData[0] !== undefined ? noxServerSlaveData[0] : '';
								noxServerSlaveData = String(noxServerSlaveData.length > 1 ? noxServerSlaveData[1] : noxServerSlaveData).split(':', 2);
								_this.noxServerSlaveAddress = noxServerSlaveData[0] !== undefined ? noxServerSlaveData[0] : '';
								_this.noxServerSlavePort = noxServerSlaveData[1] !== undefined ? noxServerSlaveData[1] : 0;
								if (_this.noxServerSlaveProtocol && !['http', 'https'].includes(_this.noxServerSlaveProtocol)) {
									_this.noxAlertServerSlave = _this.getError(8);
								}
								else if (!_this.$store.state.noxRegexServerName.test(_this.noxServerSlaveAddress) && !_this.$store.state.noxRegexServerAddr.test(_this.noxServerSlaveAddress)) {
									_this.noxAlertServerSlave = _this.getError(8);
								}
								else if (!_this.$store.state.noxRegexServerPort.test(_this.noxServerSlavePort)) {
									_this.noxAlertServerSlave = _this.getError(8);
								}
							}
						}
						else if (_this.noxServerType == 1) {
							if (!_this.noxServerRelay) {
								_this.noxAlertServerRelay = _this.getError(9);
							}
							else {
								var noxServerRelayData = _this.noxServerRelay.split('://', 2);
								_this.noxServerRelayProtocol = noxServerRelayData.length > 1 && noxServerRelayData[0] !== undefined ? noxServerRelayData[0] : '';
								noxServerRelayData = String(noxServerRelayData.length > 1 ? noxServerRelayData[1] : noxServerRelayData).split(':', 2);
								_this.noxServerRelayAddress = noxServerRelayData[0] !== undefined ? noxServerRelayData[0] : '';
								_this.noxServerRelayPort = noxServerRelayData[1] !== undefined ? noxServerRelayData[1] : 0;
								if (_this.noxServerRelayProtocol && !['http', 'https'].includes(_this.noxServerRelayProtocol)) {
									_this.noxAlertServerRelay = _this.getError(10);
								}
								else if (!_this.$store.state.noxRegexServerName.test(_this.noxServerRelayAddress) && !_this.$store.state.noxRegexServerAddr.test(_this.noxServerRelayAddress)) {
									_this.noxAlertServerRelay = _this.getError(10);
								}
								else if (!_this.$store.state.noxRegexServerPort.test(_this.noxServerRelayPort)) {
									_this.noxAlertServerRelay = _this.getError(10);
								}
							}
						}
						if (_this.noxServerDescription && !_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxServerDescription)) {
							_this.noxAlertServerDescription = _this.getError(12);
						}
						if (!_this.noxIsError) {
							config.data = { id: _this.noxId, server_id: _this.noxServerId, master_protocol: _this.noxServerMasterProtocol, master_address: _this.noxServerMasterAddress, master_port: _this.noxServerMasterPort, slave_protocol: _this.noxServerSlaveProtocol, slave_address: _this.noxServerSlaveAddress, slave_port: _this.noxServerSlavePort, relay_protocol: _this.noxServerRelayProtocol, relay_address: _this.noxServerRelayAddress, relay_port: _this.noxServerRelayPort, description: _this.noxServerDescription, type: _this.noxServerType };
							config.method = _this.noxType == 'addServer' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteServer') {
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addServer') {
									_this.$store.state.noxServerSettings.push(data.data);
								}
								else if (_this.noxType == 'editServer') {
									_this.$store.state.noxServerSettings.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'deleteServer') {
									_this.$store.state.noxServerSettings.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addServer', 'editServer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'SERVER_ID_IS_EMPTY') { _this.noxAlertServerId = _this.getError(3); }
										else if (data.response.data.error == 'SERVER_ID_NOT_VALID') { _this.noxAlertServerId = _this.getError(4); }
										else if (data.response.data.error == 'MASTER_PROTOCOL_IS_EMPTY') { _this.noxAlertServerMaster = _this.getError(6); }
										else if (data.response.data.error == 'MASTER_PROTOCOL_NOT_VALID') { _this.noxAlertServerMaster = _this.getError(6); }
										else if (data.response.data.error == 'MASTER_ADDRESS_IS_EMPTY') { _this.noxAlertServerMaster = _this.getError(6); }
										else if (data.response.data.error == 'MASTER_ADDRESS_NOT_VALID') { _this.noxAlertServerMaster = _this.getError(6); }
										else if (data.response.data.error == 'MASTER_PORT_IS_EMPTY') { _this.noxAlertServerMaster = _this.getError(6); }
										else if (data.response.data.error == 'MASTER_PORT_NOT_VALID') { _this.noxAlertServerMaster = _this.getError(6); }
										else if (data.response.data.error == 'SLAVE_PROTOCOL_IS_EMPTY') { _this.noxAlertServerSlave = _this.getError(8); }
										else if (data.response.data.error == 'SLAVE_PROTOCOL_NOT_VALID') { _this.noxAlertServerSlave = _this.getError(8); }
										else if (data.response.data.error == 'SLAVE_ADDRESS_IS_EMPTY') { _this.noxAlertServerSlave = _this.getError(8); }
										else if (data.response.data.error == 'SLAVE_ADDRESS_NOT_VALID') { _this.noxAlertServerSlave = _this.getError(8); }
										else if (data.response.data.error == 'SLAVE_PORT_IS_EMPTY') { _this.noxAlertServerSlave = _this.getError(8); }
										else if (data.response.data.error == 'SLAVE_PORT_NOT_VALID') { _this.noxAlertServerSlave = _this.getError(8); }
										else if (data.response.data.error == 'RELAY_PROTOCOL_IS_EMPTY') { _this.noxAlertServerRelay = _this.getError(10); }
										else if (data.response.data.error == 'RELAY_PROTOCOL_NOT_VALID') { _this.noxAlertServerRelay = _this.getError(10); }
										else if (data.response.data.error == 'RELAY_ADDRESS_IS_EMPTY') { _this.noxAlertServerRelay = _this.getError(10); }
										else if (data.response.data.error == 'RELAY_ADDRESS_NOT_VALID') { _this.noxAlertServerRelay = _this.getError(10); }
										else if (data.response.data.error == 'RELAY_PORT_IS_EMPTY') { _this.noxAlertServerRelay = _this.getError(10); }
										else if (data.response.data.error == 'RELAY_PORT_NOT_VALID') { _this.noxAlertServerRelay = _this.getError(10); }
										else if (data.response.data.error == 'DESCRIPTION_IS_EMPTY') { _this.noxAlertServerDescription = _this.getError(11); }
										else if (data.response.data.error == 'DESCRIPTION_NOT_VALID') { _this.noxAlertServerDescription = _this.getError(12); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlert = _this.getError(13); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlert = _this.getError(14); }
									}
									else if (_this.noxType == 'deleteServer') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editServer', 'deleteServer'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(15); }
									}
								}
								else if (data.response.status == 409) {
									if (['addServer', 'editServer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'SERVER_ID_DUPLICATE') { _this.noxAlertServerId = _this.getError(16); }
										else if (data.response.data.error == 'MASTER_DUPLICATE') { _this.noxAlertServerMaster = _this.getError(17); }
										else if (data.response.data.error == 'SLAVE_DUPLICATE') { _this.noxAlertServerSlave = _this.getError(18); }
										else if (data.response.data.error == 'RELAY_DUPLICATE') { _this.noxAlertServerRelay = _this.getError(19); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalServerSettings');
			}
		}
	}
</script>
